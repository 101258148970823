<div class="row page-work justify-content-center align-items-center">
  <div class="col-12 col-md-4 col-lg-3 page-work--info">
    <h1>Tamamlanan Projeler</h1>

    <p>
      Gerçekleştirmek istediğiniz bir web projeniz var ise benimle iletişime geçebilirsiniz.
    </p>

    <button [routerLink]="'/teklif'" class="offer-button">
      Proje Teklifi Al
    </button>
  </div>

  <div class="col-12 col-md-8 col-lg-9 page-work--list">
    <div *ngIf="Filter.status" class="d-block w-100 text-muted mb-4">
      <a routerLink="/projeler" class="back-button">
        <i class="las la-angle-left"></i>
        Geri Dön
      </a>

      * {{ Filter.query }} ile etiketlenen projeler.
    </div>

    <swiper #swiper [config]="SwiperConfig">
      <div *ngFor="let item of Items" class="swiper-slide">
        <app-item [item]="item"></app-item>
      </div>
    </swiper>
  </div>
</div>
