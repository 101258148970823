<footer class="row mx-0 mt-4 pt-4 align-items-center justify-content-center">
  <div class="col-12 col-md-auto mt-4 say-hola">
    Tüm yazılım ihtiyaçlarınız için
    <a href="https://api.whatsapp.com/send?phone=905533300010&text=Merhaba!&source=emreacar.com.tr" target="_blank" class="whatsapp">
      <span>
        <i class="lab la-whatsapp"></i>
        selam verin
      </span>
    </a> veya <br />
    <span class="text-transform-italic">
      info@emreacar.com.tr
    </span>'ye yazın.
  </div>

  <div class="col-12 col-md-auto mt-4 info">
    <p>
      FSM Bulvarı, Nilüfer / BURSA <span>|</span> T. <a href="tel://+905533300010">+905533300010</a> <span>|</span> E. info@emreacar.com.tr
    </p>
  </div>
  <div class="col-12 my-4 text-center">
    <p style="font-size: .6rem;">Bursa Web Yazılım ve Tasarım Hizmetleri, CRM Yazılımları, E-Ticaret Sistemleri ve İhtiyaca Yönetik Web Tabanlı Yazılımlar için benimle iletişime geçebilirsiniz.</p>
  </div>
</footer>
