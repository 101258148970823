<ul [class]="['social-items', direction]">
  <li>
    <a href="https://facebook.com/acaremre" target="_blank">
      <i class="lab la-facebook"></i>
    </a>
  </li>

  <li>
    <a href="https://linkedin.com/in/mracar" target="_blank">
      <i class="lab la-linkedin-in"></i>
    </a>
  </li>

  <li>
    <a href="https://github.com/emreacar" target="_blank">
      <i class="lab la-github"></i>
    </a>
  </li>

  <li>
    <a href="https://api.whatsapp.com/send?phone=905533300010&text=Merhaba!&source=emreacar.com.tr" target="_blank">
      <i class="lab la-whatsapp"></i>
    </a>
  </li>
</ul>
