import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SlackService {
  messageObject;

  constructor(private http: HttpClient) {}

  private createMessage(formData) {
    let message = '';
    Object.keys(formData).forEach((item) => {
      message += `${item}: ${formData[item]} \n`
    })

    return message
  }

  send(formData) {
    const text = this.createMessage(formData)
    const hook = 'https://hooks.slack.com/services/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'
    const hookData = {
      channel: '#web-contacts',
      text
    }

    const options = {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/x-www-form-urlencoded' }
      )
    };
    try {
      this.http.post(hook, hookData, options).subscribe();
      return true
    } catch(e) {
      console.log(e.message)
    }

    return false
  }

}
