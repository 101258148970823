import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SlackService } from './../slack.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm;
  formError;
  formSuccess;
  submitted: boolean = false;
  loading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private slackService: SlackService
  ) {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      about: ['', [Validators.required, Validators.minLength(50)]]
    })
  }

  ngOnInit(): void {}
  get contactFormData() { return this.contactForm.controls; }
  onSubmit() {
    this.submitted = true;

    if (this.contactForm.invalid) {
      return
    }
    const send2Slack = this.slackService.send(this.contactForm.value)
    this.loading = true;

    if (send2Slack) {
      this.submitted = false
      this.contactForm.reset()
      this.formSuccess = 'Teşekkürler! En kısa süre içerisinde iletişim bilgileriniz üzerinden sizinle iletişime geçeceğim.'
    } else {
      this.formError = 'Mesajınızı gönderemedim! Lütfen bir süre sonra tekrar deneyin.';
    }

    this.loading = false
  }
}
