<div class="row page-contact justify-content-center align-items-center">
  <div class="col-12 col-md-4 col-lg-3 page-contact--info">
    <h1>İletişim</h1>

    <p>
      Her zaman iletişimde kalabilmemiz çok önemli. Benimle iletişime geçebileceğiniz kanallar. Dilerseniz iletişim formunu doldurarak mesajınızdan anından haberdar olmamı sağlayabilirsiniz.
    </p>

    <app-social direction="horizantal"></app-social>
  </div>

  <div class="col-12 col-md-8 col-lg-9 page-contact--form">
    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" class="row justify-content-center">
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label>İsim Soyad</label>
          <input
            type="text"
            formControlName="name"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && contactFormData.name.errors }"
          />
          <div *ngIf="submitted && contactFormData.name.errors" class="invalid-feedback">
            <div
              *ngIf="contactFormData.name.errors.required"
            >
              Bu alanı doldurmadınız.
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>E-Mail Adresiniz</label>
          <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && contactFormData.email.errors }" />
          <div *ngIf="submitted && contactFormData.email.errors" class="invalid-feedback">
            <div
              *ngIf="contactFormData.email.errors.required || contactFormData.email.errors.email"
            >
              Mail adresinizi kontrol edin.
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>İletişim Telefon</label>
          <input type="text" formControlName="phone" class="form-control" [ngClass]="{ 'is-invalid': submitted && contactFormData.phone.errors }" />
          <div *ngIf="submitted && contactFormData.phone.errors" class="invalid-feedback">
            <div
              *ngIf="contactFormData.phone.errors.required"
            >
              Bu alanı doldurmadınız.
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>Mesajınız</label>
          <textarea type="text" formControlName="about" class="form-control" [ngClass]="{ 'is-invalid': submitted && contactFormData.about.errors }"></textarea>
          <div *ngIf="submitted && contactFormData.about.errors" class="invalid-feedback">
            <div
              *ngIf="contactFormData.about.errors.required || contactFormData.about.errors.minlength"
            >
             Lütfen mesajınızı daha detaylı anlatmaya çalışın.
            </div>
          </div>
        </div>

        <div class="form-group">
          <div *ngIf="formError" class="alert alert-danger">
            {{ formError }}
          </div>

          <div *ngIf="formSuccess" class="alert alert-success">
            {{ formSuccess }}
          </div>
          <button type="submit" class="offer-button" [disabled]="loading">
            <span *ngIf="!loading">Gönder</span>
            <i *ngIf="loading" class="las la-spin la-spinner"></i>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
