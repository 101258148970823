import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SlackService } from './../slack.service';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss']
})
export class OfferComponent implements OnInit {
  offerForm;
  formError;
  formSuccess;
  submitted: boolean = false;
  loading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private slackService: SlackService
  ) {
    this.offerForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      about: ['', [Validators.required, Validators.minLength(50)]]
    })

  }

  ngOnInit(): void {}
  get offerFormData() { return this.offerForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.offerForm.invalid) {
      return
    }
    this.loading = true;
    const send2Slack = this.slackService.send(this.offerForm.value)

    if (send2Slack) {
      this.submitted = false
      this.offerForm.reset()
      this.formSuccess = 'Teşekkürler! En kısa süre içerisinde iletişim bilgileriniz üzerinden sizinle iletişime geçeceğim.'
    } else {
      this.formError = 'Mesajınızı gönderemedim! Lütfen bir süre sonra tekrar deneyin.';
    }

    this.loading = false
  }
}
