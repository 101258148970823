import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  language = 'en'
  constructor() { }

  ngOnInit(): void {
  }

  changeLang(language = 'tr') {
    this.language = language
  }

}
