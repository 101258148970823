<div class="work-item">
  <div class="work-item--image">
    <img [defaultImage]="" [lazyLoad]="'assets/projects/' + item.image" class="img-fluid" />
    <div *ngIf="item.target" class="external-link">
      <a [href]="item.target" [title]="item.title" target="_blank">
        <i class="las la-external-link-alt"></i>
      </a>
    </div>
  </div>
  <div class="work-item--content">
    <div class="tags">
      <a
        *ngFor="let tag of item.tags"
        [routerLink]="'/projeler/' + tag"
        class="badge badge-light mr-1"
      >
        {{ tag }}
      </a>
    </div>
    <h5>{{ item.title }}</h5>
    <p>{{ item.description }}</p>
  </div>
</div>
