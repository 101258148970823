import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'workFilter'
})
export class WorkFilterPipe implements PipeTransform {

  transform(values: any[], filter: any): any {
    if (!filter.status) return values

    return values.filter(
      (value) => value.tags.includes(filter.query)
    );
  }

}
