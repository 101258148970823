import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { WorkComponent } from './work/work.component';
import { OfferComponent } from './offer/offer.component';
import { ContactComponent } from './contact/contact.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/emre-acar-kimdir',
    pathMatch: 'full'
  },
  {
    path: 'emre-acar-kimdir',
    component: AboutComponent
  },
  {
    path: 'projeler',
    component: WorkComponent
  },
  {
    path: 'projeler/:tag',
    component: WorkComponent
  },
  {
    path: 'teklif',
    component: OfferComponent
  },
  {
    path: 'iletisim',
    component: ContactComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
