<header>
  <div class="row py-2 py-md-0">
    <div class="col-12 align-self-start text-md-center pt-md-4">
      <button (click)="toggleNav()" class="menu-toggler d-inline-block d-md-none">
        <i *ngIf="!navStatus" class="las la-bars"></i>
        <i *ngIf="navStatus" class="las la-times"></i>
      </button>

      <div class="logo d-inline-block ml-4 ml-md-0 font-weight-bolder">
        <a routerLink="/about" class="d-inline-block" title="Emre Acar">
          <app-logo></app-logo>
        </a>
      </div>
    </div>

    <app-menu
      [class]="[
        'col-12',
        'align-self-md-center',
        'text-center',
        'menu',
        navStatus ? 'toggle-active' : 'toggle-passive'
      ]"
      (navItemClicked)="toggleNav()"
    ></app-menu>


    <div
      class="col-12 text-center align-self-md-end"
      [class]="navStatus ? 'd-block' : 'd-none d-md-block'">
      <app-social [direction]="navStatus ? 'horizantal' : 'vertical'"></app-social>
    </div>
  </div>
</header>
