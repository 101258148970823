import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss']
})
export class WorkComponent implements OnInit {
  SwiperConfig: SwiperConfigInterface = {
    slidesPerView: 2,
    centeredSlides: true,
    watchSlidesProgress: true,
    observer: true,
    mousewheel: {
      invert: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    spaceBetween: 30,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1
      },
      768: {
        slidesPerView: 2,
      }
    }
  };
  Filter: any = {
    status: false,
    query: ''
  }

  ItemsData: any = [
    {
      title: 'Neslive - E-Ticaret Portalı',
      description: 'Özelleştirilmiş varyant yönetimi, ürün gruplama ve ürün bağlama özellikleri ve kendine özgü tasarımı ile yine özgün hale getirilmiş bir opencart projesi.',
      target: 'https://neslive.com',
      image: 'neslive.png',
      tags: ['PHP', 'OpenCart', 'Psd2Html', 'REDIS'],
      slug: 'neslive-eticaret-projesi'
    },
    {
      title: 'İyilik İçin Kodla Web Sitesi',
      description: 'Özelleştirilebilir dinamik formlar, kapsamlı başvuru yönetimi ve tamamen ihtiyaçlara yönelik geliştirilen bir yönetim paneli.',
      target: 'https://iyilikicinkodla.com',
      image: 'iik.png',
      tags: ['NodeJS', 'MongoDB', 'VueJS'],
      slug: 'iyilik-icin-kodla-projesi'
    },
    {
      title: 'Netgalaksi - Online İşlem Merkezi',
      description: 'Fatura entegrasyonu, online ödeme sistemi, destek sistemi ve daha nice birleşik çözümler sunan online abone yönetim yazılımı',
      target: 'https://netgalaksi.com.tr/oim',
      image: 'netgalaksi-oim.png',
      tags: ['PHP', 'MYSQL', 'Custom-Api'],
      slug: 'netgalaksi-online-islem-merkezi'
    },
    {
      title: 'Livastil - E-Ticaret',
      description: 'O artık bir opencart değil! İş akışına uygun, benzersiz ve tutarlı modülleri ile birlikte özgün bir e-ticaret portalı.',
      target: 'https://livastil.com',
      image: 'livastil-web-sitesi.png',
      tags: ['PHP', 'OpenCart', 'Custom-Design'],
      slug: 'livastil-eticaret-projesi'
    },
    {
      title: 'Netgalaksi - CRM App',
      description: 'Ödeme Servisi, Fatura Entegrasyonu, Abone ve Hizmet yönetimi, Telekom Entegrasyonu, Güvenli İnternet Altyapısı ve özenle geliştirilen Mikrotik + Radius Servisleriyle tam çözüm ISS CRM',
      target: 'https://livastil.com',
      image: 'netgalaksi-crm.png',
      tags: ['NodeJS', 'VueJS', 'Custom-Api', 'MongoDB'],
      slug: 'netgalaksi-crm-application'
    },
    {
      title: 'Bursa B.B.S - Haber Portalı',
      description: 'Web sitesi yönetmek hiç bu kadar kolay olmadı! Özenle kurgulanan yönetim paneli ve ihtiyaca yönetik yazılan eşsiz modülleri sayesinde kendi içeriğini kendisi oluşturan portal',
      target: 'https://bursabbspor.com',
      image: 'bursa-bbsk-web-sitesi.png',
      tags: ['NodeJS', 'VueJS', 'Custom-Api', 'MongoDB', 'Psd2Html'],
      slug: 'bursa-buyuk-sehir-spor-web-sitesi'
    },
    {
      title: 'Müdavim Kart Sistemi',
      description: 'Müşterilerinizi takip etmenin binbir yolu var! Müşterilerine puan kazandıran, kazançları takip eden, ödüllendiren, sevdiren ve sevilen sadakat sistemi',
      target: 'https://www.sonsetbursa.com/mudavim/',
      image: 'sonset-mudavim-kart.png',
      tags: ['PHP', 'MySql', 'Custom-Api'],
      slug: 'sonset-bursa-mudavim-kart'
    },
    {
      title: 'Tablet Menü Uygulaması',
      description: 'Müşterilerinizi takip etmenin binbir yolu var! Müşterilerine puan kazandıran, kazançları takip eden, ödüllendiren, sevdiren ve sevilen sadakat sistemi',
      target: false,
      image: 'tablet-menu.png',
      tags: ['VueJS', 'NodeJS', 'Custom-Api'],
      slug: 'sonset-bursa-tablet-menu'
    }
  ]

  Items: []

  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(routeParams => {
      this.Filter.status = false

      if (routeParams.tag) {
        this.Filter.status = true
        this.Filter.query = routeParams.tag

        this.Items = this.ItemsData.filter(
          (item) => item.tags.includes(this.Filter.query)
        )
      } else {
        this.Items = this.ItemsData
      }
    });
  }
}
