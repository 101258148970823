<ul class="menu-items">
  <li>
    <a
      [routerLink]="['/emre-acar-kimdir']" routerLinkActive="active"
      (click)="itemClicked()"
    >
      Hakkımda
    </a>
  </li>

  <li>
    <a
      [routerLink]="['/projeler']" routerLinkActive="active"
      (click)="itemClicked()"
    >
      Projeler
    </a>
  </li>

  <li>
    <a
      [routerLink]="['/teklif']" routerLinkActive="active"
      (click)="itemClicked()"
    >
      Fiyat Teklifi
    </a>
  </li>

  <li>
    <a
      [routerLink]="['/iletisim']" routerLinkActive="active"
      (click)="itemClicked()"
    >
      İletişim
    </a>
  </li>
</ul>
