<div class="row page-offer justify-content-center align-items-center">
  <div class="col-12 col-md-4 col-lg-3 page-offer--info">
    <h1>Proje Teklifi</h1>

    <p>
      Web sayfaları ve uygulamaları, CRM ve ERP sistemleri, E-Ticaret yazılım ve entegrasyonları ile ilgili benimle çalışmak isterseniz bilgilendirme formunu doldurabilirsiniz.
    </p>
  </div>

  <div class="col-12 col-md-8 col-lg-9 page-offer--form">
    <form [formGroup]="offerForm" (ngSubmit)="onSubmit()" class="row justify-content-center">
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label>İsim Soyad</label>
          <input
            type="text"
            formControlName="name"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && offerFormData.name.errors }"
          />
          <div *ngIf="submitted && offerFormData.name.errors" class="invalid-feedback">
            <div
              *ngIf="offerFormData.name.errors.required"
            >
              Bu alanı doldurmadınız.
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>E-Mail Adresiniz</label>
          <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && offerFormData.email.errors }" />
          <div *ngIf="submitted && offerFormData.email.errors" class="invalid-feedback">
            <div
              *ngIf="offerFormData.email.errors.required || offerFormData.email.errors.email"
            >
              Mail adresinizi kontrol edin.
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>İletişim Telefon</label>
          <input type="text" formControlName="phone" class="form-control" [ngClass]="{ 'is-invalid': submitted && offerFormData.phone.errors }" />
          <div *ngIf="submitted && offerFormData.phone.errors" class="invalid-feedback">
            <div
              *ngIf="offerFormData.phone.errors.required"
            >
              Bu alanı doldurmadınız.
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>Projeniz Hakkında</label>
          <textarea type="text" formControlName="about" class="form-control" [ngClass]="{ 'is-invalid': submitted && offerFormData.about.errors }"></textarea>
          <div *ngIf="submitted && offerFormData.about.errors" class="invalid-feedback">
            <div
              *ngIf="offerFormData.about.errors.required || offerFormData.about.errors.minlength"
            >
             Lütfen projenizi daha detaylı anlatmaya çalışın.
            </div>
          </div>
        </div>

        <div class="form-group">
          <div *ngIf="formError" class="alert alert-danger">
            {{ formError }}
          </div>

          <div *ngIf="formSuccess" class="alert alert-success">
            {{ formSuccess }}
          </div>
          <button type="submit" class="offer-button" [disabled]="loading">
            <span *ngIf="!loading">Gönder</span>
            <i *ngIf="loading" class="las la-spin la-spinner"></i>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
