<div id="ea-logo">
  <object>
    <svg
      version="1.1"
      id="ea-svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 250 32"
      enable-background="new 0 0 250 32"
      xml:space="preserve"
    >
      <g id="group-letters">
        <path class="letters letter-e" fill="none" stroke="#000000" d="M35.6,28.3c0,2.3-1.2,3.5-3.5,3.5h-10c-2.3,0-3.5-1.2-3.5-3.5V5.1c0-2.3,1.2-3.5,3.5-3.5h10
          c2.3,0,3.5,1.2,3.5,3.5V16l-1.4,1.5H22.7v11h8.9v-5.5h4V28.3z M31.6,14.1V5h-8.9v9.1H31.6z"/>
        <path class="letters letter-m hide-start" fill="none" stroke="#000000" d="M72.9,31.8h-4.1V5.3l-9,0.2v26.3h-4.1V5.3l-9,0.2v26.3h-4.1V1.6h4.1v0.8
          c1.6-0.2,3.2-0.4,4.9-0.7c2-0.2,3.6-0.4,4.9-0.4c1.3,0,2.2,0.4,2.8,1.2c1.7-0.2,3.5-0.5,5.2-0.7c2.3-0.3,4-0.4,5.1-0.4
          c2.3,0,3.4,1.1,3.4,3.4V31.8z"/>
        <path class="letters letter-r hide-start" fill="none" stroke="#000000" d="M95.4,11.5h-4.1V5.3l-7.6,0.2v26.3h-4.1V1.6h4.1v0.8c1.4-0.2,2.7-0.4,4.1-0.7
          c1.7-0.2,3-0.4,4.1-0.4c2.3,0,3.4,1.1,3.4,3.4V11.5z"/>
        <path class="letters letter-e1 hide-start" fill="none" stroke="#000000" d="M117.6,28.3c0,2.3-1.2,3.5-3.5,3.5h-10c-2.3,0-3.5-1.2-3.5-3.5V5.1c0-2.3,1.2-3.5,3.5-3.5h10
          c2.3,0,3.5,1.2,3.5,3.5V16l-1.4,1.5h-11.5v11h8.9v-5.5h4V28.3z M113.6,14.1V5h-8.9v9.1H113.6z"/>
        <path class="letters letter-a" fill="none" stroke="#000000" d="M143.8,31.8H137v-1.1c-1.6,0.2-3.2,0.5-4.9,0.7c-2.1,0.3-3.7,0.5-4.8,0.5
          c-2.6,0-3.9-1.3-3.9-3.8V16.8c0-2.6,1.3-3.9,4-3.9h9.6V5.7h-6.8v4.5h-6.4V4.3c0-2.6,1.3-3.9,4-3.9h12c2.6,0,4,1.3,4,3.9V31.8z
          M137.1,26.2v-8.4h-6.8v8.7L137.1,26.2z"/>
        <path class="letters letter-c hide-start" fill="none" stroke="#000000" d="M169.3,27.9c0,2.6-1.3,3.9-4,3.9H153c-2.7,0-4-1.3-4-3.9V4.3c0-2.6,1.3-3.9,4-3.9h12.4
          c2.6,0,4,1.3,4,3.9v7.5h-6.9V6.3h-6.4v19.6h6.4v-6h6.9V27.9z"/>
        <path class="letters letter-a1 hide-start" fill="none" stroke="#000000" d="M194.6,31.8h-6.8v-1.1c-1.6,0.2-3.2,0.5-4.9,0.7c-2.1,0.3-3.7,0.5-4.8,0.5
          c-2.6,0-3.9-1.3-3.9-3.8V16.8c0-2.6,1.3-3.9,4-3.9h9.6V5.7h-6.8v4.5h-6.4V4.3c0-2.6,1.3-3.9,4-3.9h12c2.6,0,4,1.3,4,3.9V31.8z
          M187.9,26.2v-8.4h-6.8v8.7L187.9,26.2z"/>
        <path class="letters letter-r1 hide-start" fill="none" stroke="#000000" d="M219.5,13.5h-7.1V6.3l-5.6,0.1v25.4h-7.1V0.4h7.1v1.2c1.5-0.3,2.9-0.5,4.4-0.8
          c1.8-0.3,3.2-0.5,4.4-0.5c2.6,0,3.9,1.3,3.9,3.8V13.5z"/>
      </g>
      <g id="group-start">
        <path class="chars chars-start" fill="none" stroke="#000000" d="M0,17.7c4.4-4.4,7.1-8.4,10.8-10.6l1.1,1.7c-2.5,2.1-6.1,5.9-8.4,8.9l8.1,8.2l-1.2,1.7
          c-3-2.1-6.1-6.6-9-8.9L0,17.7z"/>
      </g>
      <g id="group-end">
        <path class="chars chars-end" fill="none" stroke="#000000" d="M223.3,28.1c5.3-11.1,7.2-14.8,8.8-18.1l2.7-5.5l1.2,0.6c-1,2.1-1.8,3.7-2.7,5.5l-8.8,18.1
          L223.3,28.1z"/>
        <path class="chars chars-end" fill="none" stroke="#000000" d="M248.7,19.7c-2.9,2.3-5.9,6.8-9,8.9l-1.2-1.7l8.1-8.2c-2.3-3-5.9-6.8-8.4-8.9l1.1-1.7
          c3.7,2.1,6.3,6.1,10.8,10.6L248.7,19.7z"/>
      </g>
    </svg>
  </object>
</div>
