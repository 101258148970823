<div class="row page-about justify-content-center">
  <div class="col-12 col-md-5 pb-4">
    <div class="row justify-content-center align-items-center mb-4">
      <div class="col-12 col-md-auto text-center">
        <img [defaultImage]="" [lazyLoad]="'assets/images/emre-acar-cv.jpg'" class="img-fluid mb-4 cv-image" />
      </div>

      <div class="col-12 text-center col-md-auto">
        <h1 class="h2">
          Emre Acar<br />
          <small>FullStack Web Developer</small>
        </h1>
        <span class="badge badge-warning m-2">
          NodeJS Developer
        </span>

        <span class="badge badge-warning m-2">
          VueJS / NuxtJS Developer
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6 border-right">

        <div class="row">
          <div class="col-12">
            <h2 class="h6">
              <i class="las la-user"></i> Profile
            </h2>

            <p>
              I was born in 91 Kütahya. I started my career in 2009 with PHP. I focused more on the Javascript language and made improvements with nodeJS on the back-end and VueJS, NuxtJS, Angular Frameworks on the front-end.
            </p>

            <p>I have been using NodeJS and VueJS since 2016. I have a personality that keeps pace with the innovativeness required by the sector, constantly researches, reads and constantly improves itself by struggling to learn more.
            </p>
          </div>

          <div class="col-12">
            <h2 class="h6">
              <i class="las la-skiing"></i> Skills
            </h2>

            <ul class="skills">
              <li>PHP</li>
              <li>Javascript</li>
              <li>TypeScript</li>
              <li>VueJS</li>
              <li>NuxtJS</li>
              <li>MongoDB</li>
              <li>Mysql/MariaDB</li>
              <li>Redis</li>
              <li>Figma / Sketch / Adobe Apps
                <span class="badge badge-info">
                  As Frontend
                </span>
              </li>
              <li>Docker
                <span class="badge badge-success">
                  Experiencing
                </span>
              </li>
              <li>Angular
                <span class="badge badge-success">
                  Experiencing
                </span>
              </li>
              <li>React
                <span class="badge badge-warning">
                  Learning
                </span>
              </li>
            </ul>
          </div>
        </div>

      </div>

      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-12">
            <h2 class="h6">
              <i class="las la-briefcase"></i> Work Experience
            </h2>

            <ul>
              <li class="exp">
                <h3 class="h6">
                  <i class="las la-braille"></i> Netgalaksi Telecommunication A.Ş
                </h3>
                <span class="exp--tag badge badge-light">
                  [May 2013 -
                  <span class="text-success">Current</span>
                  ]
                </span>
                <span class="exp--tag badge badge-light">
                  FullStack Developer
                </span>

                <p class="exp--desc">
                  Netgalaksi is a company serving in the telecommunication sector. Designing the entire informatics infrastructure, web systems and accounting interface of the company, controlling the server infrastructures of the company and ensuring the data traffic between Türk Telekom and Türk Telekom.

                  Technologies used within the company;
                </p>
                <ul>
                  <li>PHP</li>
                  <li>NodeJS</li>
                  <li>VueJS</li>
                  <li>MongoDB / Mysql</li>
                  <li>Redis</li>
                  <li>Docker</li>
                </ul>
              </li>

              <li class="exp">
                <h3 class="h6">
                  <i class="las la-braille"></i> Beymarka Digital Adversiting Agency
                </h3>
                <span class="exp--tag badge badge-light">
                  [Feb 2015 - Nov 2016]
                </span>
                <span class="exp--tag badge badge-light">
                  FullStack Developer
                </span>

                <p class="exp--desc">
                  Creation of need-oriented CRM - ERP software, Hybrid Mobile Applications, Social Media Integration Software
                </p>
              </li>

              <li class="exp">
                <h3 class="h6">
                  <i class="las la-braille"></i> Haylaz Digital Ads. Agency
                </h3>
                <span class="exp--tag badge badge-light">
                  [Aug 2014 - Jan 2015]
                </span>
                <span class="exp--tag badge badge-light">
                  Frontend Developer
                </span>

                <span class="exp--tag badge badge-light">
                  [Part-Time]
                </span>

                <p class="exp--desc">
                  Interface designs of the websites and the html / css casting of the .psd designs from the graphic department.
                </p>
              </li>

              <li class="exp">
                <h3 class="h6">
                  <i class="las la-braille"></i> Elite Agency
                </h3>
                <span class="exp--tag badge badge-light">
                  [Feb 2011 - Mar 2012]
                </span>
                <span class="exp--tag badge badge-light">
                  PHP Developer
                </span>

                <p class="exp--desc">
                  Developing php-based e-commerce systems, creating customized systems such as business tracking systems, corporate management panels.
                </p>
              </li>

              <li class="exp">
                <h3 class="h6">
                  <i class="las la-braille"></i> Ezop Advertising Agency
                </h3>
                <span class="exp--tag badge badge-light">
                  [Jun 2009 - Feb 2010]
                </span>
                <span class="exp--tag badge badge-light">
                  Frontend Developer
                </span>

                <p class="exp--desc">
                  In the workplace where I volunteered to improve myself, the interface designs of the websites and the html / css casting of the .psd designs from the graphic department.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="language == 'tr'">
      <button class="btn btn-sm btn-light small-text" (click)="changeLang('en')">
        [click for english]
      </button>
      <h1>Hakkımda</h1>

      <p>Ağustos 91, Kütahya doğumlu. Lise eğitimini İnegöl Anadolu Lisesi'nde tamamladı. Üniversite eğitimini Bursa Uludağ Üniversitesi Fizik Bölümün'de devam ettirdi, halen Bursa'da yaşıyor.</p>

      <p>Meslek hayatına lise mezuniyeti sonrasında (2009) PHP yazılım dili ile başladı. Yazılımın yanı sıra Photoshop, Illustrator ve Sketch / Figma programlarına hakim. Javascript dili üzerine daha fazla yoğunlaşıp back-end tarafında nodeJS, front-end tarafında VueJS, NuxtJS, Angular Frameworkleri ile geliştirmeler yaptı. PHP MVC ve OOP mimarisine hakim, nodeJS ve VueJS son derece uzman ve sektörün gerektirdiği yenilikçiliğe ayak uyduran, durmadan araştıran okuyan ve daha fazlasını öğrenmek için bocalayıp kendini sürekli geliştiren bir kişiliğe sahip.</p>

      <p>İşi gereği sadece server-side yazılım değil, client-side yazılım konusunda da uzmanlaşmıştır.</p>

      <p>Tercihen Mysql, ihtiyaca göre mongoDB - redis v.b veritabanları üzerinde deneyim sahibi, bir projenin sadece çalışır olmasını değil aynı zamanda performanslı ve güvenli olması gerektiğini kendine prensip olarak belirlemiştir.</p>

      <p>Javascript dilinin vermiş olduğu esnekliği kullanarak ionic (angularJS), vue-native gibi kütüphaneleri kullanmış, back-end tecrübesiyle birlikte adapte, hibrit mobil uygulamalar geliştirmiştir.</p>
    </div>

    <div *ngIf="language == 'en'">
      <button class="btn btn-sm btn-light small-text" (click)="changeLang('tr')">
        [click for turkish]
      </button>
      <h1>About Me</h1>

      <p>He was born on August 91 in Kütahya. He completed his high school education at İnegöl Anatolian High School. He continued his university education in Bursa Uludağ University Physics Department and still lives in Bursa.</p>

      <p>He started his career after high school graduation (2009) with PHP software language. In addition to software, he has good command of Photoshop, Illustrator and Sketch / Figma programs. He focused more on the Javascript language and made improvements with nodeJS on the back-end and VueJS, NuxtJS, Angular Frameworks on the front-end. Mastering PHP MVC and OOP architecture, nodeJS and VueJS are highly expert and have a personality that keeps pace with the innovation required by the industry, constantly researches, reads and struggles to learn more.</p>

      <p>Preferably, Mysql, having experience on mongoDB and Redis databases according to the needs, has determined to itself as a principle that a project should not only be operational, but also performance and secure.</p>

      <p>Using the flexibility given by the Javascript language, it has used libraries such as ionic (angularJS), vue-native, and has developed adapted, hybrid mobile applications with its back-end experience.
      </p>
    </div>
  </div>

</div>
