import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  navStatus: boolean = false
  constructor() { }

  ngOnInit(): void {
  }

  toggleNav() {
    this.navStatus = !this.navStatus
  }

}
